var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import untar from 'isomorphic-untar-gzip';
import { fetchWithRetries } from '../utils';
/**
 * Responsible for fetching, caching and converting tars to a structure that sandpack
 * understands and can use
 */
export class TarStore {
    constructor() {
        this.fetchedTars = {};
    }
    generateKey(name, version) {
        return name + '||' + version;
    }
    normalizeTar(tarContents) {
        const normalized = {};
        tarContents.forEach(tar => {
            normalized[tar.name.replace(/^[^/]+/, '')] = {
                // TODO(@CompuIves): store buffers rather than strings for binary files
                content: Buffer.from(tar.buffer).toString(),
            };
        });
        return normalized;
    }
    fetchTar(name, version, requestInit) {
        const tarKey = this.generateKey(name, version);
        this.fetchedTars[tarKey] = (() => __awaiter(this, void 0, void 0, function* () {
            const file = yield fetchWithRetries(version, 6, requestInit).then(x => x.arrayBuffer());
            const untarredFile = yield untar(file);
            const normalizedTar = this.normalizeTar(untarredFile);
            return normalizedTar;
        }))();
        return this.fetchedTars[tarKey];
    }
    file(name, url, path, requestInit) {
        return __awaiter(this, void 0, void 0, function* () {
            const tarKey = this.generateKey(name, url);
            const tar = yield (this.fetchedTars[tarKey] ||
                this.fetchTar(name, url, requestInit));
            return tar[path].content;
        });
    }
    meta(name, url, requestInit) {
        return __awaiter(this, void 0, void 0, function* () {
            const tarKey = this.generateKey(name, url);
            const tar = yield (this.fetchedTars[tarKey] ||
                this.fetchTar(name, url, requestInit));
            const meta = {};
            Object.keys(tar).forEach(path => {
                meta[path] = true;
            });
            return meta;
        });
    }
    massFiles(name, url, requestInit) {
        return __awaiter(this, void 0, void 0, function* () {
            const tarKey = this.generateKey(name, url);
            const tar = yield (this.fetchedTars[tarKey] ||
                this.fetchTar(name, url, requestInit));
            return Object.keys(tar).map(path => ({
                path,
                code: tar[path].content,
                downloaded: true,
            }));
        });
    }
}
